(function ($, app, _) {
    "use strict";

    app.LazyNavbar = function () {

            $(function () {
                if (app.isMobile()) {
                    /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
                    var prevScrollpos = window.pageYOffset;

                    window.onscroll = function() {
                        var currentScrollPos = window.pageYOffset;
                        if (prevScrollpos < currentScrollPos && currentScrollPos > 80) {
                            document.getElementById("navbar").style.top = "-100px";
                            document.getElementById("navbar").style.transition = "top 0.5s";
                        } else {
                            document.getElementById("navbar").style.top = "0";
                        }
                        prevScrollpos = currentScrollPos;
                    }
                }
            });
        }
})(jQuery, app, _);


