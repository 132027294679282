/* mixins  */

(function ($, app, _) {
    "use strict";

    app.AddClassToChildren = function () {
        $('.m2-content.light25 p').addClass('light25');
        $('.m2-content.light25 a').addClass('large');
        $('.m17-content p').addClass('light16');
        $('.m22-content p').addClass('light16');
    };

})(jQuery, app, _);