/* options module */

(function ($, app, _) {
	"use strict";

	app.AOS = function () {

        AOS.init({
            disable: 'mobile',
            duration: 1000
        })
	};

})(jQuery, app, _);