(function ($, app, _) {
    "use strict";

    app.AddCircleAnimation = function () {

        $('#c1,#b1').click(function(){
            $('.rrethi-vogel li.active').removeClass('active');
            $('#c1').parent('li').addClass('active');

            $(".rrethi-bullets>ul>li.active").removeClass("active");
            $(".rrethi-bullets>ul>li.rrethi-bullets-1").addClass("active");

            $('.radial-progress').attr('data-progress', Math.floor(0));
        });
        $('#c2,#b2').click(function(){
            $('.rrethi-vogel li.active').removeClass('active');
            $('#c2').parent('li').addClass('active');

            $(".rrethi-bullets>ul>li.active").removeClass("active");
            $(".rrethi-bullets>ul>li.rrethi-bullets-2").addClass("active");

            $('.radial-progress').attr('data-progress', Math.floor(20));
        });
        $('#c3,#b3').click(function(){
            $('.rrethi-vogel li.active').removeClass('active');
            $('#c3').parent('li').addClass('active');

            $(".rrethi-bullets>ul>li.active").removeClass("active");
            $(".rrethi-bullets>ul>li.rrethi-bullets-3").addClass("active");

            $('.radial-progress').attr('data-progress', Math.floor(40));
        });
        $('#c4,#b4').click(function(){
            $('.rrethi-vogel li.active').removeClass('active');
            $('#c4').parent('li').addClass('active');

            $(".rrethi-bullets>ul>li.active").removeClass("active");
            $(".rrethi-bullets>ul>li.rrethi-bullets-4").addClass("active");

            $('.radial-progress').attr('data-progress', Math.floor(60));
        });
        $('#c5,#b5').click(function(){
            $('.rrethi-vogel li.active').removeClass('active');
            $('#c5').parent('li').addClass('active');

            $(".rrethi-bullets>ul>li.active").removeClass("active");
            $(".rrethi-bullets>ul>li.rrethi-bullets-5").addClass("active");

            $('.radial-progress').attr('data-progress', Math.floor(80));
        });

    };

})(jQuery, app, _);