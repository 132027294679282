
/* add class on scroll */

(function ($, app, _) {
    "use strict";

    app.AddClassScroll = function () {

        //caches a jQuery object containing the header element
        var header = $(".clearHeader");
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            if (scroll >= 200) {
                $('.site-header').addClass("scrolled");
            } else {
                $('.site-header').removeClass("scrolled");
            }
        });
    };

})(jQuery, app, _);