/* constructor */

var app = {};
window.app = app || {};

var _mfq = _mfq || [];

(function ($, app, _) {
  _.extend(app, {
    appBaseUrl: app_js_base,

    $body: $('body'),
    $menu: $('.site-navigation'),

    fadeTime: 200,
    fadeTimeSlow: 400,
    fadeTimeSlower: 750,

    timeOut: 2000,
    timeOutLong: 4000,

    scrollTriggerPos: 40,
    scrollSubNavShrink: 50,
    collapseTime: 200,
    navHeight: 61,
    childPos: 0,
    gridFloatPoint: 991,
    scrollOffset: 0,
  });
})(jQuery, app, _);
