/* options module */

(function ($, app, _) {
    "use strict";

    app.SwiperSliderSetup = function () {
        if (!app.isMobile()) {
            var swiper = new Swiper('.swiper-container.home-slider', {


                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                speed: 1,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false,
                },
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '"><p></p></span>';
                    },
                },

                breakpoints: {

                    // when window width is <= 991px
                    991: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },


                },

                on: {
                    slideChangeTransitionStart: function () {
                        $('.do-animation').hide(0);
                        $('.do-animation').removeClass('aos-init').removeClass('aos-animate');
                    },
                    slideChangeTransitionEnd: function () {
                        $('.do-animation').show(0);
                        AOS.init();
                    },
                }
            });
        }
        if (app.isMobile()) {
            var swiper = new Swiper('.swiper-container.home-slider', {
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '"><p></p></span>';
                    },
                },
            });
        }

        var swiper = new Swiper('.swiper-container.tab-slider', {



            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"><p></p></span>';
                },
            },
            on: {
                slideChangeTransitionStart: function () {

                },
                slideChangeTransitionEnd: function () {
                    $('.collapse').collapse('hide');
                },
            }


        });


        var swiper = new Swiper('.swiper-container.location-slider', {
            // Optional parameters
            slidesPerView: 2,
            spaceBetween: 0,
            loop: false,

            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            // And if we need scrollbar
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            breakpoints: {
                1136: {
                    slidesPerView: 1,
                },
            }
        });

        var swiper = new Swiper('.quote-m11-slider', {
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            slidesPerView: 1,
            spaceBetween: 24,
            autoplay: {
                delay: 8000,
                disableOnInteraction: false,
            },
            loop: true,
            navigation: {
                nextEl: '.swiper-h-button-next',
                prevEl: '.swiper-h-button-prev',
            },
            pagination: {
                el: '.swiper-ho-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"><p></p></span>';
                },
            }
        });


        var swiper = new Swiper('.m4-slider', {
            fadeEffect: {
                crossFade: true,
            },
            slidesPerView: 1,
            spaceBetween: 24,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            loop: true,
            navigation: {
                nextEl: '.swiper-h-button-next',
                prevEl: '.swiper-h-button-prev',
            },
            pagination: {
                el: '.swiper-ho-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"><p></p></span>';
                },
            }
        });


        var swiper = new Swiper('.swiper-container.karriere-slider', {

            autoplay: {
                delay: 12000,
                disableOnInteraction: false,
            },
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"><p></p></span>';
                },
            }
        });

        var swiper = new Swiper('.swiper-container.slider-new-header', {

            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            },
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"><p></p></span>';
                },
            }
        });

        AOS.init();

    };

})(jQuery, app, _);