/* main file */

(function ($, app) {

  // Immediately (before anything goes)
  (function () {
  })();

  // When DOM is ready
    $(window).on('ready', function() {

  });

  // When everything is rendered
    $(window).on('load', function() {
        app.LazyNavbar();
        app.setDimensions();
        app.AddClassScroll();
        app.AOS();
        app.SwiperSliderSetup();
        app.AddClassToChildren();
        app.AddCircleAnimation();
        app.setTabActiveAnchor();

        setInterval(function(){
            jQuery('.m1').addClass("section-half-gray-home")
        }, 2300);
    });

})(jQuery, app);



// Select all links with hashes
$('a.smoothscroll')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });


jQuery('.wp-polls-ul li' ).append('<div class="check"></div>');

$(".navbar-toggler").click(function(){
    $("#tbackground").toggle();
});


window.addEventListener('load', AOS.refresh);



$(window).bind('resize', function(e)
{
  if (window.RT) clearTimeout(window.RT);
  window.RT = setTimeout(function()
  {

	var maxHeight = -1;



    $('.same-height').each(function() {
		maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
	});

	$('.same-height').each(function() {
		$(this).height(maxHeight);
	});


	$('.same-height2').each(function() {
		maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
	});

	$('.same-height2').each(function() {
		$(this).height(maxHeight);
	});

	$('.same-height3').each(function() {
		maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
	});

	$('.same-height3').each(function() {
		$(this).height(maxHeight);
	});


  }, 100);
});




$(document).ready(function() {
    
   

    !function(s){s.fn.shorten=function(e){var t={showChars:100,ellipsesText:"...",moreText:"more",lessText:"less"};return e&&s.extend(t,e),s(document).off("click",".morelink"),s(document).on({click:function(){var e=s(this);return e.hasClass("less")?(e.removeClass("less"),e.html(t.moreText)):(e.addClass("less"),e.html(t.lessText)),e.parent().prev().toggle(),e.prev().toggle(),!1}},".morelink"),this.each(function(){var e=s(this);if(!e.hasClass("shortened")){e.addClass("shortened");var n=e.html();if(n.length>t.showChars){var r=n.substr(0,t.showChars),a=n.substr(t.showChars,n.length-t.showChars),l=r+'<span class="moreellipses">'+t.ellipsesText+' </span><span class="morecontent"><span>'+a+'</span> <a href="#" class="morelink">'+t.moreText+"</a></span>";e.html(l),s(".morecontent span").hide()}}})}}(jQuery);

    $(".m36 .light16").shorten({
        "showChars" : 60,
        "moreText"	: "Mehr Erfahren",
        "lessText"	: "Weniger lesen",
    });
});


Vue.component('Portfolio', {
	data() {
		return {
			modal: false,
			post: {},
			posts: [],
			visibility: false,
			selected: null,
			catID: null,
			hash: '',
			selectbox: 'all'
		}
	},

	props: {
		projects: Array,
		cats: Array,
		url: String
	},

	mounted() {
		if(location.hash) {
			this.$refs.all.classList.remove('list-item--is-active');
		}
	},

	async created() {
		this.hash = location.hash.slice(1);
		
		if(location.hash) {
			const getHash = location.hash.slice(1);
			this.selectbox = this.hash;

			this.cats.filter(cat => {
				if(cat.slug === getHash) this.catID = cat.cat_ID
			})

			try {
				const res = await fetch(`${this.url}/wp-json/wp/v2/portfolio?categories=${this.catID}&_embed&per_page=100`);
				this.posts = await res.json();	

				this.visibility = true;

				if(this.$refs.cta) this.$refs.cta.style.display = 'none';
				this.$refs.grid.style.display = 'none';
				
			} catch (error) {
				console.log(error)
			}
		}
	},

	methods: {
		async filter(cat, index) {
			this.hash = '';

			this.selected = index;
			this.$refs.all.classList.remove('list-item--is-active');
			this.posts = [];

			location.hash = cat.slug;

			try {
				const res = await fetch(`${this.url}/wp-json/wp/v2/portfolio?categories=${cat.cat_ID}&_embed&per_page=100`);
				this.posts = await res.json();	

				this.visibility = true;
				this.$refs.cta.style.display = 'none';
				this.$refs.grid.style.display = 'none';
				
			} catch (error) {
				console.log(error)
			}
		},

		async getPost(id) {
			try {
				const res = await fetch(`${this.url}/wp-json/wp/v2/portfolio/${id}?_embed&_fields=id,title,acf,links&per_page=100`);
				this.post = await res.json();
				this.modal = true;

			} catch (error) {
				console.log(error)
			}
		},

		async selectPosts(e) {
			this.posts = [];

			this.cats.filter(cat => {
				if(cat.slug === e.target.value) this.catID = cat.cat_ID
			})

			location.hash = e.target.value;

			if(e.target.value === 'all') {
				history.replaceState(null, null, ' ');
				location.reload();	
			}

			try {
				const res = await fetch(`${this.url}/wp-json/wp/v2/portfolio?categories=${this.catID}&_embed&per_page=100`);
				this.posts = await res.json();	

				this.visibility = true;
				this.$refs.grid.style.display = 'none';
				
			} catch (error) {
				console.log(error)
			}
		},
		
		reset() {
			history.replaceState(null, null, ' ');
			this.selected = null;
			this.hash = '';
			this.visibility = false;
			this.$refs.all.classList.add('list-item--is-active');
			this.$refs.cta.style.display = 'block';
			this.$refs.grid.style.display = 'block';

			macy.runOnImageLoad(function () {
				spinner.remove(); 
				macy.recalculate(true, true);
			});
		}
	}
})

let vm = new Vue({
	el: '#m46'
}) 

let macy = Macy({
	container: '#grid',
	trueOrder: false,
	waitForImages: false,
	debug: true,
	margin: 25,
	columns: 3,
	mobileFirst: true,
	breakAt: {
			1025: 3,
			940: 1,
			320: 1
	}
});

const spinner = document.querySelector('.spinner');

macy.runOnImageLoad(function () {
	spinner.remove(); 
	macy.recalculate(true, true);
});
