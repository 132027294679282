var $container;
var filters = {};

$(function () {

    $container = $('#container');


    //var $filterDisplay = $('#filter-display');


    $container.isotope();
    // do stuff when checkbox change
    $('#options').on('change', function (jQEvent) {
        //$('.hide-this').show();
        $('.content-hub').removeClass("hide-this");
        $('.loadMore').hide();
        var $checkbox = $(jQEvent.target);
        manageCheckbox($checkbox);

        var comboFilter = getComboFilter(filters);

        $container.isotope({filter: comboFilter});

        if (!$container.data('isotope').filteredItems.length) {
            $("#no-results").css('display', 'block');
        }
        if ($container.data('isotope').filteredItems.length) {
            $("#no-results").css('display', 'none');
        }

        //$filterDisplay.text(comboFilter);
        updateFilterCount();
    });


    var iso = $container.data('isotope');
    var $filterCount = $('.filter-count');

    function updateFilterCount() {
        $filterCount.text( iso.filteredItems.length + ' Ergebnisse' );
    }

});



function getComboFilter(filters) {
    var i = 0;
    var comboFilters = [];
    var message = [];

    for (var prop in filters) {
        message.push(filters[prop].join(' '));
        var filterGroup = filters[prop];
        // skip to next filter group if it doesn't have any values
        if (!filterGroup.length) {
            continue;
        }
        if (i === 0) {
            // copy to new array
            comboFilters = filterGroup.slice(0);
        } else {
            var filterSelectors = [];
            // copy to fresh array
            var groupCombo = comboFilters.slice(0); // [ A, B ]
            // merge filter Groups
            for (var k = 0, len3 = filterGroup.length; k < len3; k++) {
                for (var j = 0, len2 = groupCombo.length; j < len2; j++) {
                    filterSelectors.push(groupCombo[j] + filterGroup[k]); // [ 1, 2 ]
                }

            }
            // apply filter selectors to combo filters for next group
            comboFilters = filterSelectors;
        }
        i++;
    }

    var comboFilter = comboFilters.join(', ');
    return comboFilter;
}




function manageCheckbox($checkbox) {
    var checkbox = $checkbox[0];

    var group = $checkbox.parents('.option-set').attr('data-group');
    // create array for filter group, if not there yet
    var filterGroup = filters[group];
    if (!filterGroup) {
        filterGroup = filters[group] = [];
    }

    var isAll = $checkbox.hasClass('all');
    // reset filter group if the all box was checked
    if (isAll) {
        delete filters[group];
        if (!checkbox.checked) {
            checkbox.checked = 'checked';
        }
    }
    // index of
    var index = $.inArray(checkbox.value, filterGroup);

    if (checkbox.checked) {
        var selector = isAll ? 'input' : 'input.all';
        $checkbox.siblings(selector).removeAttr('checked');


        if (!isAll && index === -1) {
            // add filter to group
            filters[group].push(checkbox.value);
        }

    } else if (!isAll) {
        // remove filter from group
        filters[group].splice(index, 1);
        // if unchecked the last box, check the all
        if (!$checkbox.siblings('[checked]').length) {
            $checkbox.siblings('input.all').attr('checked', 'checked');
        }
    }

}

$(".toggle-filter").click(function () {
    $("#options").toggle();
});
$(".filter-reset").click(function () {
    $("#contenthema-all").trigger("click");
    $("#contentart-all").trigger("click");
    //$("#branche-all").trigger("click");
    //("#autor-all").trigger("click");
    $('input:checkbox').each(function () {
        this.checked = false;
    });
    $('.remove-filter').remove();
    //$('.hide-this').show();
    $('.content-hub').removeClass("hide-this");
});

function unCheck(valueChecked) {
    console.log($(valueChecked).attr('value'));
    valueRemove = $(valueChecked).attr('value');
    $('input:checkbox[value="' + valueRemove + '"]').trigger("click");
}

$(document).ready(function () {
    $('.filter-set input').change(function () {
        var value = $(this).attr("value");
        var label = $(this).next('label').text();
        if (this.checked) {
            $(".selected-filter").append("<div class='remove-filter' value='" + value + "' onclick='unCheck(this)'>" + label + " <img src='/wp-content/themes/premedia/images/svg/close.svg'></div>");
            console.log('added');
        } else {
            $('.remove-filter[value="' + value + '"]').remove();
            console.log('removed');
        }
    });


    $('#loadMore').click( function() {

        //$('.hide-this').show();
        $('.content-hub').removeClass("hide-this");
        $("#contenthema-all").trigger("click");
        $("#contentart-all").trigger("click");
        $("#branche-all").trigger("click");
        $("#autor-all").trigger("click");
        $('input:checkbox').each(function () {
            this.checked = false;
        });

        $('.loadMore').hide();


    });
    $('.remove-filter').click( function() {
        //$('.hide-this').show();
        $('.content-hub').removeClass("hide-this");
    });

    $('.triangle').click( function() {
        $(this).siblings('.show-filter-set').toggle();
        $(this).siblings('.main-content-hub').toggleClass('opened');
        $(this).toggleClass('opened');
    });


});